import { createSlice } from '@reduxjs/toolkit';
import firebaseService from '@ameroservices-platform/shared/services/firebase';

const cmsSlice = createSlice({
	name: 'cmsApp',
	initialState: {
		loading: false,
		fetchingData: true,
		templatesOrganisation: {},
		templatesGlobal: {},
		elementTemplates: {},
		content: {},
		languages: {},
		eventGroups: null,
		lists: null
	},
	reducers: {
		setLoading: {
			reducer: (state, action) => {
				state.loading = action.payload;
			}
		},
		setFetchingData: {
			reducer: (state, action) => {
				state.fetchingData = action.payload;
			}
		},
		setTemplatesOrganisation: {
			reducer: (state, action) => {
				state.templatesOrganisation = action.payload;
			}
		},
		setTemplatesGlobal: {
			reducer: (state, action) => {
				state.templatesGlobal = action.payload;
			}
		},
		setElementTemplates: {
			reducer: (state, action) => {
				state.elementTemplates = action.payload;
			}
		},
		setContent: {
			reducer: (state, action) => {
				state.content = action.payload;
			}
		},
		setLanguages: {
			reducer: (state, action) => {
				state.languages = action.payload;
			}
		},
		setEventGroups(state, action) {
			state.eventGroups = action.payload;
		},
		setLists(state, action) {
			state.lists = action.payload;
		}
	}
});

export const {
	setLoading,
	setFetchingData,
	setTemplatesOrganisation,
	setTemplatesGlobal,
	setElementTemplates,
	setContent,
	setLanguages,
	setEventGroups,
	setLists
} = cmsSlice.actions;

export const createTemplateOrganisation = (name, type, content) => async dispatch => {
	dispatch(setLoading(true));
	if (typeof content !== 'string') {
		content = JSON.stringify(content);
	}
	const resp = await firebaseService.callFunctionByName('contentTemplatesCreateTemplate', {
		name,
		type,
		content
	});
	dispatch(setLoading(false));
	return resp.data.id;
};

export const updateTemplateOrganisation = data => async dispatch => {
	dispatch(setLoading(true));
	if (typeof data.data.content !== 'string') {
		data.data.content = JSON.stringify(data.data.content);
	}
	await firebaseService.callFunctionByName('contentTemplatesUpdateTemplate', data);
	dispatch(setLoading(false));
};

export const deleteTemplateOrganisation = templateUid => async dispatch => {
	dispatch(setLoading(true));
	await firebaseService.callFunctionByName('contentTemplatesDeleteTemplate', {
		templateUid
	});
	dispatch(setLoading(false));
};

export const createTemplateGlobal = (name, type, content) => async dispatch => {
	dispatch(setLoading(true));
	if (typeof content !== 'string') {
		content = JSON.stringify(content);
	}
	const resp = await firebaseService.callFunctionByName('templatesCreateTemplate', {
		name,
		type,
		content
	});
	dispatch(setLoading(false));
	return resp.data.id;
};

export const updateTemplateGlobal = data => async dispatch => {
	dispatch(setLoading(true));
	if (typeof data.data.content !== 'string') {
		data.data.content = JSON.stringify(data.data.content);
	}
	await firebaseService.callFunctionByName('templatesUpdateTemplate', data);
	dispatch(setLoading(false));
};

export const deleteTemplateGlobal = templateUid => async dispatch => {
	dispatch(setLoading(true));
	await firebaseService.callFunctionByName('templatesDeleteTemplate', {
		templateUid
	});
	dispatch(setLoading(false));
};

export const templatesListenerOrganisation = () => dispatch => {
	const db = firebaseService.getContentRootDB();
	dispatch(setLoading(true));
	return db
		.collection('templates')
		.where('deleted', '==', false)
		.onSnapshot(query => {
			const templates = {};
			query.forEach(doc => {
				const template = doc.data();
				if (template.content && typeof template.content === 'string') {
					template.content = JSON.parse(template.content);
				}
				templates[doc.id] = { ...template, id: doc.id };
			});
			dispatch(setTemplatesOrganisation(templates));
			dispatch(setLoading(false));
		});
};

export const templatesListenerGlobal = () => dispatch => {
	const db = firebaseService.getRootDB();
	dispatch(setLoading(true));
	return db
		.collection('templates')
		.where('deleted', '==', false)
		.onSnapshot(query => {
			const templates = {};
			query.forEach(doc => {
				const template = doc.data();
				if (template.content && typeof template.content === 'string') {
					template.content = JSON.parse(template.content);
				}
				templates[doc.id] = { ...template, id: doc.id };
			});
			dispatch(setTemplatesGlobal(templates));
			dispatch(setLoading(false));
		});
};

export const createElementTemplate = (name, content) => async dispatch => {
	dispatch(setLoading(true));
	if (typeof content !== 'string') {
		content = JSON.stringify(content);
	}
	const resp = await firebaseService.callFunctionByName('elementTemplatesCreateElementTemplate', {
		name,
		content
	});
	dispatch(setLoading(false));
	return resp.data.id;
};

export const elementTemplatesListener = () => dispatch => {
	const db = firebaseService.getRootDB();
	dispatch(setLoading(true));
	return db
		.collection('elementTemplates')
		.where('deleted', '==', false)
		.onSnapshot(query => {
			const elementTemplates = {};
			query.forEach(doc => {
				const template = doc.data();
				if (template.content && typeof template.content === 'string') {
					template.content = JSON.parse(template.content);
				}
				elementTemplates[doc.id] = { ...template, id: doc.id };
			});
			dispatch(setElementTemplates(elementTemplates));
			dispatch(setLoading(false));
		});
};

export const updateElementTemplate = data => async dispatch => {
	dispatch(setLoading(true));
	if (typeof data.data.content !== 'string') {
		data.data.content = JSON.stringify(data.data.content);
	}
	await firebaseService.callFunctionByName('elementTemplatesUpdateElementTemplate', data);
	dispatch(setLoading(false));
};

export const deleteElementTemplate = elementTemplateUid => async dispatch => {
	dispatch(setLoading(true));
	await firebaseService.callFunctionByName('elementTemplatesDeleteElementTemplate', {
		elementTemplateUid
	});
	dispatch(setLoading(false));
};

export const setCurrentCmsLanguage = language => async dispatch => {
	dispatch(setLoading(true));
	await firebaseService.callFunctionByName('contentSetCurrentCmsLanguage', { language });
	dispatch(setLoading(false));
};

export const setDefaultLanguage = language => async dispatch => {
	dispatch(setLoading(true));
	await firebaseService.callFunctionByName('contentSetDefaultLanguage', { language });
	dispatch(setLoading(false));
};

export const contentListener = () => dispatch => {
	dispatch(setLoading(true));
	return firebaseService.contentListener(data => {
		if (data) {
			dispatch(setContent(data));
			dispatch(setLoading(false));
		}
	});
};

export const createLanguage = async data => {
	const resp = await firebaseService.callFunctionByName('contentLanguagesCreateLanguage', data);
	return resp.data;
};

export const deleteLanguage = async (organisationUid, languageId) => {
	const resp = await firebaseService.callFunctionByName('contentLanguagesDeleteLanguage', {
		languageId: languageId,
		organisationUid
	});
	return resp.data;
};

/* export const languageListener = (languageUid, callback) => {
	const db = firebaseService.getContentRootDB();
	return db
		.collection('languages')
		.doc(languageUid)
		.onSnapshot(doc => {
			const data = { ...doc.data(), id: doc.id };
			callback(data);
		});
}; */

export const languagesListener = () => dispatch => {
	if (!firebaseService.getOrganisationId()) {
		return () => {};
	}
	const db = firebaseService.getContentRootDB();
	dispatch(setLoading(true));
	return db
		.collection('languages')
		.where('deleted', '==', false)
		.onSnapshot(query => {
			const languages = {};
			query.forEach(doc => {
				const language = doc.data();
				languages[doc.id] = { ...language, id: doc.id };
			});
			dispatch(setLanguages(languages));
			dispatch(setLoading(false));
		});
};

export const eventGroupsListener = () => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return firebaseService.salesChannelListener(db.collection('eventGroups').where('deleted', '==', false), data => {
		dispatch(setEventGroups(data));
	});
};

export const listsListener = () => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return firebaseService.salesChannelListener(db.collection('lists').where('deleted', '==', false), data => {
		dispatch(setLists(data));
	});
};

export default cmsSlice.reducer;

export const selectLoading = state => state.cmsApp.loading;
export const selectTemplatesOrganisation = state => state.cmsApp.templatesOrganisation;
export const selectTemplatesGlobal = state => state.cmsApp.templatesGlobal;
export const selectElementTemplates = state => state.cmsApp.elementTemplates;
export const selectContent = state => state.cmsApp.content;
export const selectLanguages = state => state.cmsApp.languages;
export const selectEventGroups = state => state.cmsApp.eventGroups;
export const selectLists = (state, allowedTypes) =>
	state.cmsApp.lists?.filter(list => allowedTypes.some(allowedType => list?.values?.[allowedType]));
