import _ from 'lodash';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import eventSchedulingType from '@ameroservices-platform/shared/enums/eventSchedulingType';
import { selectNavigation, updateNavigationItem as updateNavigationItemFuse } from '../../store/fuse/navigationSlice';
import { removeNavChildren } from '../../../AttUtils';
import { setLoading } from '../../main/apps/frontendSettings/pages/pagesSlice';

export const eventGroupsForSchedulersNavigationListener = eventGroupTypeFeatureFlag => (dispatch, getState) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('eventGroups')
		.where('deleted', '==', false)
		.onSnapshot(q => {
			let eventGroups = [];
			q.forEach(doc => {
				eventGroups.push({ ...doc.data(), id: doc.id });
			});
			const typeKey = eventGroupTypeFeatureFlag ? 'eventScheduling' : 'type';
			eventGroups = eventGroups
				.filter(eventGroup => !eventGroup[typeKey] || eventGroup[typeKey] === eventSchedulingType.AUTOMATIC)
				.map(n => ({
					id: `eventSchedulers-group-${n.id}`,
					title: n.name,
					type: 'item',
					icon: 'wallpaper',
					url: `/apps/settings/event-schedulers/${n.id}`,
					exact: true
				}));
			const navigation = selectNavigation(getState());
			const adminNavigation = navigation.find(n => n.id === 'admin');
			const eventConfigNavigation = adminNavigation.children.find(n => n.id === 'eventConfiguration');
			if (eventConfigNavigation) {
				const eventSchedulerNavigation = eventConfigNavigation.children.find(n => n.id === 'eventSchedulers');
				if (!_.isEqual(eventSchedulerNavigation.children, eventGroups)) {
					removeNavChildren(dispatch, getState, 'eventSchedulers');
					dispatch(
						updateNavigationItemFuse('eventSchedulers', {
							id: 'eventSchedulers',
							title: 'Planlægning',
							type: 'collapse',
							icon: 'wallpaper',
							children: eventGroups
						})
					);
				}
			}
		});
};

export const eventAttributeGroupsForNavigationListener = () => (dispatch, getState) => {
	const db = firebaseService.getOrganisationRootDB();
	return firebaseService.salesChannelListener(
		db.collection('eventAttributeGroups').where('deleted', '==', false),
		eventAttributeGroups => {
			eventAttributeGroups = eventAttributeGroups.map(n => ({
				id: `eventAttributeGroup-custom-${n.id}`,
				title: n.name,
				type: 'item',
				icon: 'event',
				url: `/apps/event-attribute-groups/${n.id}`,
				auth: ['setup.eventAttributeGroups'],
				exact: true
			}));
			eventAttributeGroups.push({
				id: `eventAttributeGroup-create`,
				title: 'Opret ny',
				type: 'item',
				icon: 'add',
				url: `/apps/event-attribute-groups`,
				auth: ['setup.eventAttributeGroups'],
				exact: true
			});
			removeNavChildren(dispatch, getState, 'eventAttributeGroups');
			dispatch(
				updateNavigationItemFuse('eventAttributeGroups', {
					id: 'eventAttributeGroups',
					title: 'Attribut Grupper',
					type: 'collapse',
					icon: 'edit_attributes',
					exact: true,
					auth: ['setup.eventAttributeGroups'],
					children: eventAttributeGroups
				})
			);
		}
	);
};

export const ticketTypesForNavigationListener = hideManagedBy => (dispatch, getState) => {
	const db = firebaseService.getOrganisationRootDB();
	return firebaseService.salesChannelListener(
		db.collection('ticketTypes').where('deleted', '==', false),
		ticketTypes => {
			ticketTypes = ticketTypes.filter(ticketType => !hideManagedBy || !ticketType.simpleProductManagement);
			ticketTypes = ticketTypes.map(n => ({
				id: `product-ticketType-${n.id}`,
				title: n.name,
				type: 'item',
				icon: 'local_activity',
				url: `/apps/products/${n.id}`,
				exact: true
			}));
			ticketTypes.splice(0, 0, {
				id: `product-ticketType-all`,
				title: 'Alle Produkter',
				type: 'item',
				icon: 'local_activity',
				url: `/apps/products`,
				exact: true
			});
			const navigation = selectNavigation(getState());
			const adminNavigation = navigation.find(n => n.id === 'admin');
			if (!adminNavigation) {
				return;
			}
			const productConfigurationNavigation = adminNavigation.children.find(n => n.id === 'productConfiguration');
			if (!productConfigurationNavigation) {
				return;
			}
			const productsNavigation = productConfigurationNavigation.children.find(n => n.id === 'products');
			if (!productsNavigation?.children) {
				return;
			}
			if (!_.isEqual(productsNavigation.children, ticketTypes)) {
				removeNavChildren(dispatch, getState, 'products');
				dispatch(
					updateNavigationItemFuse('products', {
						id: 'products',
						title: 'Produkter',
						type: 'collapse',
						icon: 'shop_two',
						exact: true,
						children: ticketTypes
					})
				);
			}
		}
	);
};

export const eventGroupsForEventsNavigationListener = eventGroupTypeFeatureFlag => (dispatch, getState) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('eventGroups')
		.where('deleted', '==', false)
		.onSnapshot(q => {
			const eventGroups = [];
			q.forEach(doc => {
				eventGroups.push({ ...doc.data(), id: doc.id });
			});
			const typeKey = eventGroupTypeFeatureFlag ? 'eventScheduling' : 'type';
			const eventGroupsAuto = eventGroups
				.filter(eg => !eg[typeKey] || eg[typeKey] === eventSchedulingType.AUTOMATIC)
				.map(n => ({
					id: `automaticEvents-${n.id}`,
					title: n.name,
					type: 'item',
					icon: 'event',
					url: `/apps/automatic-events/${n.id}`,
					exact: true
				}));
			const eventGroupsManual = eventGroups
				.filter(eg => eg[typeKey] && eg[typeKey] === eventSchedulingType.MANUAL)
				.map(n => ({
					id: `manualEvents-${n.id}`,
					title: n.name,
					type: 'item',
					icon: 'event',
					url: `/apps/manual-events/${n.id}`,
					exact: true
				}));
			const navigation = selectNavigation(getState());
			const userNavigation = navigation.find(n => n.id === 'user');
			const manualEventsNavigation = userNavigation.children.find(n => n.id === 'manualEvents');
			const autoEventsNavigation = userNavigation.children.find(n => n.id === 'automaticEvents');
			const updateManual =
				manualEventsNavigation && !_.isEqual(manualEventsNavigation.children, eventGroupsManual);
			const updateAuto = autoEventsNavigation && !_.isEqual(autoEventsNavigation.children, eventGroupsAuto);
			if (updateManual) {
				removeNavChildren(dispatch, getState, 'manualEvents');
			}
			if (updateAuto) {
				removeNavChildren(dispatch, getState, 'automaticEvents');
			}
			if (updateManual) {
				dispatch(
					updateNavigationItemFuse('manualEvents', {
						id: 'manualEvents',
						title: 'Manuelle Begivenheder',
						type: 'collapse',
						icon: 'event',
						children: eventGroupsManual
					})
				);
			}
			if (updateAuto) {
				dispatch(
					updateNavigationItemFuse('automaticEvents', {
						id: 'automaticEvents',
						title: 'Automatiske Begivenheder',
						type: 'collapse',
						icon: 'event',
						children: eventGroupsAuto
					})
				);
			}
		});
};

export const eventAttributeGroupsListener = callback => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('eventAttributeGroups')
		.where('deleted', '==', false)
		.onSnapshot(query => {
			callback(query.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		});
};

export const locationsNavigationListener = callback => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('locations')
		.where('deleted', '==', false)
		.onSnapshot(query => {
			callback(query.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		});
};

export const eventGroupsForEventsNavigationListenerFeatureFlagEventGroupType = callback => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('eventGroups')
		.where('deleted', '==', false)
		.onSnapshot(query => {
			callback(query.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		});
};

export const pageContentsForContentNavigationListener = () => dispatch => {
	const db = firebaseService.getContentRootDB();
	dispatch(setLoading(true));
	return db
		.collection('pages')
		.where('deleted', '==', false)
		.where('salesChannelUid', '==', firebaseService.getSalesChannelId() || null)
		.orderBy('content')
		.onSnapshot(q => {
			let pages = [];
			q.forEach(doc => {
				pages.push({ ...doc.data(), id: doc.id });
			});
			pages = pages.map(n => ({
				id: `pageContent-${n.id}`,
				title: n.uri,
				type: 'item',
				icon: 'web',
				url: `/apps/frontend-settings/pages/${n.id}/content`,
				auth: ['setup.frontend.pageContent'],
				exact: true
			}));
			dispatch(
				updateNavigationItemFuse('pageContents', {
					id: 'pageContents',
					title: 'Side indhold',
					type: 'collapse',
					icon: 'web',
					auth: ['setup.frontend.pageContent'],
					children: pages
				})
			);
			dispatch(setLoading(false));
		});
};
