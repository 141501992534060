import React, { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const EventGroupContentWrapper = lazy(() => import('./eventGroupContent/EventGroupContentWrapper'));
const EventGroupLanguageContent = lazy(() => import('./eventGroupContent/EventGroupLanguageContent'));
const SubscriptionEvents = lazy(() => import('./eventGroups/EventGroups'));
const EntranceEvents = lazy(() => import('./eventGroups/EventGroups'));
const EventEvents = lazy(() => import('./eventGroups/EventGroups'));
const EventGroup = lazy(() => import('./eventGroup/EventGroup'));
const EventGroups = lazy(() => import('./eventGroups/EventGroups'));

const EventGroupsAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/settings/event-groups/:eventGroupUid/content',
			element: <EventGroupContentWrapper />
		},
		{
			path: '/apps/settings/event-groups/:eventGroupUid/:languageUid/content',
			element: <EventGroupLanguageContent />
		},
		{
			path: '/apps/event-group/content/:eventGroupUid/:languageUid',
			element: <EventGroupLanguageContent />
		},
		{
			path: '/apps/event-group/content/:eventGroupUid',
			element: <EventGroupContentWrapper />
		},
		{
			// add eventGroupType here
			path: '/apps/settings/event-groups/subscriptionEvents',
			element: <SubscriptionEvents />
		},
		{
			path: '/apps/settings/event-groups/entranceEvents',
			element: <EntranceEvents />
		},
		{
			path: '/apps/settings/event-groups/eventEvents',
			element: <EventEvents />
		},
		{
			path: '/apps/settings/event-groups/:eventGroupUid',
			element: <EventGroup />
		},
		{
			path: '/apps/settings/event-groups',
			element: <EventGroups />
		}
	]
};

export default EventGroupsAppConfig;
