import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import clsx from 'clsx';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState, memo } from 'react';
import Autosuggest from 'react-autosuggest';
import { useDispatch } from 'react-redux';
import OrganisationSelectorChip from './OrganisationSelectorChip';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import { showMessage } from '@ameroservices-platform/attraction-backend/app/store/fuse/messageSlice';
import { makeStyles } from '@mui/styles';
import {
	Dialog,
	DialogTitle,
	Icon,
	IconButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Popper,
	TextField,
	Tooltip,
	Typography
} from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import Paper from '@mui/material/Paper';
import organisationType from '@ameroservices-platform/shared/enums/organisationType';
import organisationState from '@ameroservices-platform/shared/enums/organisationState';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';

function getSalesChannels() {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('salesChannels')
		.where('deleted', '==', false)
		.get()
		.then(query => {
			return query.docs.map(doc => {
				const data = doc.data();
				return {
					...data,
					organisationUid: firebaseService.getOrganisationId(),
					salesChannelUid: doc.id,
					icon: 'home',
					type: 'salesChannel'
				};
			});
		});
}

function getOrganisations() {
	const db = firebaseService.getRootDB();
	return db
		.collection('organisations')
		.where('deleted', '==', false)
		.where('state', 'in', [organisationState.PRODUCTION, organisationState.TEST])
		.get()
		.then(query => {
			return query.docs
				.map(doc => ({ ...doc.data(), id: doc.id }))
				.filter(organisation => !organisation.type || organisation.type === organisationType.ATTRACTION)
				.reduce((acc, cur) => {
					acc.push({ ...cur, organisationUid: cur.id, icon: 'apartment', type: 'organisation' });
					if (cur.salesChannels && Object.values(cur.salesChannels).length >= 2) {
						acc.push(
							...Object.entries(cur.salesChannels).map(([id, salesChannel]) => ({
								...salesChannel,
								organisationUid: cur.id,
								salesChannelUid: id,
								icon: 'home',
								type: 'salesChannel',
								name: `${cur.name} - ${salesChannel.name}`
							}))
						);
					}
					return acc;
				}, []);
		});
}

function SearchField(inputProps) {
	const { variant, classes, inputRef = () => {}, ref, ...other } = inputProps;
	return (
		<div className="w-full relative">
			<TextField
				fullWidth
				InputProps={{
					disableUnderline: true,
					inputRef: node => {
						ref(node);
						inputRef(node);
					},
					classes: {
						input: clsx(classes.input, 'py-0 px-16 h-64')
					}
				}}
				variant="standard"
				{...other}
			/>
		</div>
	);
}

function SearchSuggestion(suggestion, { query, isHighlighted }) {
	const matches = match(suggestion.name, query);
	const parts = parse(suggestion.name, matches);

	return (
		<MenuItem selected={isHighlighted} component="div">
			<ListItemIcon className="min-w-40">
				{suggestion.icon ? (
					<Icon>{suggestion.icon}</Icon>
				) : (
					<span className="text-20 w-24 font-bold uppercase text-center">{suggestion.name[0]}</span>
				)}
			</ListItemIcon>
			<ListItemText
				primary={parts.map((part, index) =>
					part.highlight ? (
						<span key={String(index)} style={{ fontWeight: 600 }}>
							{part.text}
						</span>
					) : (
						<strong key={String(index)} style={{ fontWeight: 300 }}>
							{part.text}
						</strong>
					)
				)}
			/>
		</MenuItem>
	);
}

const useStyles = makeStyles(theme => ({
	root: {},
	container: {
		position: 'relative'
	},
	suggestionsContainerOpen: {
		position: 'absolute',
		zIndex: 1,
		marginTop: theme.spacing(),
		left: 0,
		right: 0
	},
	suggestion: {
		display: 'block'
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none'
	},
	input: {
		transition: theme.transitions.create(['background-color'], {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.short
		}),
		'&:focus': {
			backgroundColor: theme.palette.background.paper
		}
	},
	inputIcon: {
		color: theme.palette.secondary.main
	}
}));

function OrganisationSelector({ onlySameOrganisation }) {
	const [organisations, setOrganisations] = useState(null);
	const [open, setOpen] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [changingOrganisation, setChangingOrganisation] = useState(null);
	const [changingSalesChannel, setChangingSalesChannel] = useState(null);
	const classes = useStyles();
	const dispatch = useDispatch();
	const suggestionsNode = useRef(null);
	const popperNode = useRef(null);

	useEffect(() => {
		if (onlySameOrganisation) {
			getSalesChannels().then(organisations => {
				setOrganisations(organisations);
			});
			return;
		}
		getOrganisations().then(organisations => {
			setOrganisations(organisations);
		});
	}, [onlySameOrganisation]);

	const suggestions = useMemo(() => {
		if (!searchText || !organisations) {
			return [];
		}
		const inputValue = _.deburr(searchText.trim()).toLowerCase();
		const inputLength = inputValue.length;
		let count = 0;

		return inputLength === 0
			? []
			: organisations.filter(suggestion => {
					const keep = count < 10 && match(suggestion.name, inputValue).length > 0;

					if (keep) {
						count += 1;
					}

					return keep;
			  });
	}, [searchText, organisations]);

	const noSuggestions = searchText && organisations && organisations.length > 0 && suggestions.length === 0;

	function escFunction(event) {
		if (event.keyCode === 27) {
			hideOrganisationSearch();
		}
	}

	function showOrganisationSearch() {
		setOpen(true);
		document.addEventListener('keydown', escFunction, false);
	}

	function hideOrganisationSearch() {
		setOpen(false);
		setSearchText('');
		document.removeEventListener('keydown', escFunction, false);
	}

	function handleSuggestionSelected(event, { suggestion }) {
		event.preventDefault();
		event.stopPropagation();
		if (onlySameOrganisation) {
			const selectedOrganisation = organisations.find(
				organisation => organisation.salesChannelUid === suggestion.salesChannelUid
			);
			setChangingOrganisation(selectedOrganisation);
		} else {
			const selectedOrganisation = organisations.find(
				organisation => organisation.id === suggestion.organisationUid
			);
			setChangingOrganisation(selectedOrganisation);
			setChangingSalesChannel(selectedOrganisation.salesChannels?.[suggestion.salesChannelUid] || null);
		}
		firebaseService
			.getFunctions()
			.httpsCallable('authSetOrganisation')({
				organisationId: suggestion.organisationUid,
				salesChannelId: suggestion.salesChannelUid || null
			})
			.then(() => {
				firebaseService.refreshToken().then(() => {
					window.location.reload();
				});
			})
			.catch(err => {
				dispatch(
					showMessage({
						message: `Fejl under organisations skift: ${err.message}`
					})
				);
				console.error('setAuthOrganisationCallable ERROR', err);
			});

		hideOrganisationSearch();
	}

	function handleSearchTextChange(event) {
		setSearchText(event.target.value);
	}

	function handleClickAway(event) {
		if (!suggestionsNode.current || !suggestionsNode.current.contains(event.target)) {
			hideOrganisationSearch();
		}
	}

	return (
		<div className={clsx(classes.root, 'flex')}>
			{!!changingOrganisation && (
				<Dialog fullWidth maxWidth={'sm'} open onClose={() => {}}>
					<DialogTitle className={'text-center'}>Skifter organisation</DialogTitle>
					<DialogContent dividers className={'flex flex-col items-center justify-center py-20 pb-48'}>
						<Typography className={'text-lg mb-20'}>
							Skifter organisation til <span className={'font-bold'}>{changingOrganisation.name}</span>
							{changingSalesChannel && (
								<>
									{' '}
									- <span className={'font-bold'}>{changingSalesChannel.name}</span>
								</>
							)}
						</Typography>
						<CircularProgress className="" color="primary" size="8rem" />
					</DialogContent>
				</Dialog>
			)}
			<Tooltip title="Skift organisation" placement="bottom">
				<div onClick={showOrganisationSearch} onKeyDown={showOrganisationSearch} role="button" tabIndex={0}>
					<OrganisationSelectorChip icon={firebaseService.getSalesChannelId() ? 'home' : 'apartment'}>
						{organisations === null && 'Henter organisationer...'}
						{organisations !== null &&
							(organisations.find(organisation => {
								if (firebaseService.getSalesChannelId()) {
									return organisation.salesChannelUid === firebaseService.getSalesChannelId();
								}
								return organisation.id === firebaseService.getOrganisationId();
							})?.name ||
								'Vælg organisation')}
					</OrganisationSelectorChip>
				</div>
			</Tooltip>

			{open && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Paper className="absolute left-0 right-0 top-0 h-full z-9999" square>
						<div className="flex items-center w-full px-16" ref={popperNode}>
							<Icon className={classes.inputIcon}>business</Icon>
							<Autosuggest
								renderInputComponent={SearchField}
								highlightFirstSuggestion
								suggestions={suggestions}
								onSuggestionsFetchRequested={() => {}}
								onSuggestionsClearRequested={() => {}}
								onSuggestionSelected={handleSuggestionSelected}
								getSuggestionValue={suggestion => suggestion.name}
								renderSuggestion={SearchSuggestion}
								inputProps={{
									classes,
									placeholder: 'Søg efter en organisation...',
									value: searchText,
									onChange: handleSearchTextChange,
									InputLabelProps: {
										shrink: true
									},
									autoFocus: true
								}}
								theme={{
									container: 'flex flex-1 w-full',
									suggestionsList: classes.suggestionsList,
									suggestion: classes.suggestion
								}}
								renderSuggestionsContainer={options => (
									<>
										{popperNode.current && (
											<Popper
												anchorEl={popperNode.current}
												modifiers={{
													preventOverflow: {
														padding: 0
													}
												}}
												open
												popperOptions={{
													positionFixed: true
												}}
												className="z-9999"
											>
												<div ref={suggestionsNode}>
													<Paper
														elevation={1}
														square
														{...options.containerProps}
														style={{
															width: popperNode.current
																? popperNode.current.clientWidth
																: null
														}}
													>
														{options.children}
														{noSuggestions && (
															<Typography className="px-16 py-12">
																Ingen resultater..
															</Typography>
														)}
													</Paper>
												</div>
											</Popper>
										)}
									</>
								)}
							/>
							<IconButton onClick={hideOrganisationSearch} className="mx-8">
								<Icon>close</Icon>
							</IconButton>
						</div>
					</Paper>
				</ClickAwayListener>
			)}
		</div>
	);
}

export default memo(OrganisationSelector);
