import React, { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const EventGroup = lazy(() => import('./wizard/EventGroup'));
const EventGroups = lazy(() => import('./list/EventGroups'));
const EventGroupDetails = lazy(() => import('./details/EventGroupDetails'));

const EventGroupsAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/event-group/create',
			element: <EventGroup />
		},
		{
			path: '/apps/event-group/edit/:documentUid',
			element: <EventGroup />
		},
		{
			path: '/apps/event-group/edit/:documentUid/:tab',
			element: <EventGroup />
		},
		{
			path: '/apps/event-groups',
			element: <EventGroups />
		},
		{
			path: '/apps/event-group/details/:documentUid',
			element: <EventGroupDetails />
		}
	]
};

export default EventGroupsAppConfig;
