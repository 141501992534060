import { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const SysAdminSysAdmin = lazy(() => import('./sysAdmin/SysAdmin'));

const SysAdminAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/sys-admin-settings',
			element: <SysAdminSysAdmin />
		}
	]
};

export default SysAdminAppConfig;
