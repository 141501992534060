import { lazy } from 'react';
import authRoles from '../../../../auth/authRoles';

// const BoothTypeBoothType = lazy(() => import('./boothType/BoothType'));
const ProductGroupsProductGroups = lazy(() => import('./productGroups/ProductGroups'));
const ProductGroupProductGroup = lazy(() => import('./productGroup/ProductGroup'));
const TicketTypeRedirect = lazy(() => import('./TicketTypeRedirect'));

const ProductGroupsAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/settings/product-groups/:ticketTypeUid',
			element: <ProductGroupProductGroup />
		},
		{
			path: '/apps/settings/product-groups',
			element: <ProductGroupsProductGroups />
		},
		{
			path: '/apps/settings/ticket-types/:ticketTypeUid',
			element: <TicketTypeRedirect />
		},
		{
			path: '/apps/settings/ticket-types',
			element: <TicketTypeRedirect />
		}
	]
};

export default ProductGroupsAppConfig;
