import FuseUtils from '@ameroservices-platform/shared/fuse/utils';
import AppContext from '@ameroservices-platform/attraction-backend/app/AppContext';
import { Component } from 'react';
import { connect } from 'react-redux';
import { matchRoutes } from 'react-router-dom';
import withRouter from '@ameroservices-platform/attraction-backend/fuse/core/withRouter';
import settingsConfig from '@ameroservices-platform/attraction-backend/app/fuse-configs/settingsConfig';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import navigationConfig from '@ameroservices-platform/attraction-backend/app/fuse-configs/navigationConfig';

class FuseAuthorization extends Component {
	constructor(props, context) {
		super(props);
		const { routes } = context;
		this.state = {
			accessGranted: true,
			routes
		};
		this.defaultLoginRedirectUrl = settingsConfig.loginRedirectUrl || '/';
	}

	componentDidMount() {
		if (!this.state.accessGranted) {
			this.redirectRoute();
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState.accessGranted !== this.state.accessGranted;
	}

	componentDidUpdate() {
		if (!this.state.accessGranted) {
			this.redirectRoute();
		}
	}

	static getDerivedStateFromProps(props, state) {
		const { location, userRole } = props;
		const { pathname } = location;

		const matchedRoutes = matchRoutes(state.routes, pathname);

		const matched = matchedRoutes ? matchedRoutes[0] : false;
		if (!matched.route.auth) {
			return {
				accessGranted: true
			};
		}
		console.log(
			'Required permission for this route:',
			matched.route.auth.map(permission =>
				firebaseService.getSalesChannelId()
					? `${firebaseService.getSalesChannelId()}.${permission}`
					: `*.${permission}`
			),
			userRole
		);
		let accessGranted = true;
		if (matched) {
			const allowedPermissions = matched.route.auth.reduce((acc, permission) => {
				if (firebaseService.getSalesChannelId()) {
					acc.push(`${firebaseService.getSalesChannelId()}.${permission}`);
				}
				acc.push(`*.${permission}`);
				return acc;
			}, []);
			accessGranted = FuseUtils.hasPermission(allowedPermissions, userRole);
		}
		if (!accessGranted) {
			console.warn('User is not authorized to access this route');
		}
		return {
			accessGranted
		};
	}

	redirectRoute() {
		const { location, userRole, navigate } = this.props;
		const { pathname } = location;
		/*
        User is guest
        Redirect to Login Page
        */
		if (!userRole || userRole.length === 0) {
			setTimeout(
				() =>
					navigate({
						pathname: '/login'
					}),
				0
			);
			settingsConfig.loginRedirectUrl = '/login';
		} else {
			/*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to dashboard or loginRedirectUrl
        */
			const findUrl = routes => {
				const route = routes.find(route => {
					return (
						!route.auth ||
						FuseUtils.hasPermission(
							route.auth.map(permission =>
								firebaseService.getSalesChannelId()
									? `${firebaseService.getSalesChannelId()}.${permission}`
									: `*.${permission}`
							),
							userRole
						)
					);
				});
				if (route?.url) {
					return route.url;
				}
				if (route?.children) {
					return findUrl(route.children);
				}
				return null;
			};
			const redirectUrl = findUrl(navigationConfig);
			if (!redirectUrl) {
				setTimeout(
					() =>
						navigate({
							pathname: '/login'
						}),
					0
				);
			} else {
				setTimeout(
					() =>
						navigate({
							pathname: redirectUrl
						}),
					0
				);
			}
		}
	}

	render() {
		// console.info('Fuse Authorization rendered', this.state.accessGranted);
		return this.state.accessGranted ? <>{this.props.children}</> : null;
	}
}

function mapStateToProps({ auth }) {
	return {
		userRole: auth.user.role
	};
}

FuseAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps)(FuseAuthorization));
