import React, { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const Product = lazy(() => import('./product/Product'));
const ProductsProducts = lazy(() => import('./products/Products'));

const ProductsAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/product/:productUid',
			element: <Product />
		},
		{
			path: '/apps/products/:ticketTypeUid',
			element: <ProductsProducts />
		},
		{
			path: '/apps/products',
			element: <ProductsProducts />
		}
	]
};

export default ProductsAppConfig;
