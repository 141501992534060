import { createSlice } from '@reduxjs/toolkit';
import { updateNavigationItem as updateNavigationItemFuse } from '../../../../store/fuse/navigationSlice';
import { removeNavChildren } from '../../../../../AttUtils';
import firebaseService from '@ameroservices-platform/shared/services/firebase';

const initialState = {
	navigations: [],
	navigation: null,
	navigationItems: [],
	allowedPages: [],
	loading: false
};

const navigationsSlice = createSlice({
	name: 'navigationsApp/navigation',
	initialState,
	reducers: {
		setNavigations(state, action) {
			state.navigations = action.payload;
		},
		setNavigation(state, action) {
			state.navigation = action.payload;
		},
		setNavigationItems(state, action) {
			state.navigationItems = action.payload;
		},
		setAllowedPages(state, action) {
			state.allowedPages = action.payload;
		},
		setLoading(state, action) {
			state.loading = action.payload;
		},
		updateNavigationItemStore(state, action) {
			const copy = [...state.navigationItems];
			const index = copy.findIndex(f => f.id === action.payload.id);
			copy[index] = action.payload.item;
			state.navigationItems = copy;
		}
	}
});

export const {
	setNavigations,
	setNavigationItems,
	setNavigation,
	updateNavigationItemStore,
	setAllowedPages,
	setLoading
} = navigationsSlice.actions;

export default navigationsSlice.reducer;

export const navigationsListener = () => (dispatch, getState) => {
	const db = firebaseService.getContentRootDB();
	return firebaseService.salesChannelListener(db.collection('navigations').where('deleted', '==', false), data => {
		dispatch(setNavigations(data));
		const navs = data.map(n => ({
			id: `navigation-custom-${n.id}`,
			title: n.type,
			type: 'item',
			icon: 'navigation',
			url: `/apps/frontend-settings/navigations/${n.id}`,
			auth: ['setup.frontend.navigations'],
			exact: true
		}));
		navs.push({
			id: 'navigation-create',
			title: 'Opret ny',
			type: 'item',
			icon: 'add',
			url: '/apps/frontend-settings/navigations',
			auth: ['setup.frontend.navigations'],
			exact: true
		});
		removeNavChildren(dispatch, getState, 'navigations');
		dispatch(
			updateNavigationItemFuse('navigations', {
				id: 'navigations',
				title: 'Navigationer',
				type: 'collapse',
				icon: 'wallpaper',
				auth: ['setup.frontend.navigations'],
				children: navs
			})
		);
	});
};

export const navigationListener = id => dispatch => {
	const db = firebaseService.getContentRootDB();
	return db
		.collection('navigations')
		.doc(id)
		.onSnapshot(q => {
			const data = q.data();
			dispatch(setNavigationItems(Object.values(data.navigation).sort((a, b) => a.order - b.order)));
			dispatch(setNavigation(data));
		});
};

export const allowedPagesListener = () => dispatch => {
	const db = firebaseService.getContentRootDB();
	return firebaseService.salesChannelListener(db.collection('pages').where('deleted', '==', false), pages => {
		pages.push({ uri: '/' });
		dispatch(setAllowedPages(pages.map(m => m.uri).filter((item, index, self) => self.indexOf(item) === index)));
	});
};

export const updateNavigation = (id, navigation) => async dispatch => {
	dispatch(setNavigation(navigation));
	const nav = {};
	let order = 0;
	navigation.forEach(n => {
		nav[n.id] = { ...n, order };
		order += 1;
	});
	await firebaseService.callFunctionByName('contentNavigationsUpdateNavigation', {
		navigationId: id,
		navigation: nav
	});
};

export const updateNavigationItem = (navigationId, id, item) => async dispatch => {
	dispatch(updateNavigationItemStore({ id, item }));
	await firebaseService.callFunctionByName('contentNavigationsUpdateNavigationItem', {
		navigationId,
		id,
		item
	});
};

export const createNavigation = async id => {
	const response = await firebaseService.callFunctionByName('contentNavigationsCreateNavigation', {
		navigationId: id
	});
	return response?.data?.id;
};

export const deleteNavigation = async id => {
	await firebaseService.callFunctionByName('contentNavigationsDeleteNavigation', {
		navigationId: id
	});
};

export const selectNavigations = state => state.navigationsApp.navigations;
export const selectNavigationItems = state => state.navigationsApp.navigationItems;
export const selectNavigation = state => state.navigationsApp.navigation;
export const selectAllowedPages = state => state.navigationsApp.allowedPages;
export const selectLoading = state => state.navigationsApp.loading;
