const organisationState = Object.freeze({
	PRODUCTION: 'production',
	TEST: 'test',
	INACTIVE: 'inactive',
	UNKNOWN: 'unknown',
});

export const organisationStateTranslate = Object.freeze({
	production: 'Produktion',
	test: 'Test',
	inactive: 'Inaktiv',
	unknown: 'Ukendt',
});

export default organisationState;
