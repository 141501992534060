import TaxRatesAppConfig from './taxRates/TaxRatesAppConfig';
import ProductGroupsAppConfig from './productGroups/ProductGroupsAppConfig';
import IncrementalNumbersAppConfig from './incrementalNumbers/IncrementalNumbersAppConfig';
import SubscriptionTypesAppConfig from './subscriptionTypes/SubscriptionTypesAppConfig';
import LocationsAppConfig from './locations/LocationsAppConfig';
import ListsAppConfig from './lists/ListsAppConfig';
import CountriesAppConfig from './countries/CountriesAppConfig';
import DiscountCodeTypesAppConfig from './discountCodeTypes/DiscountCodeTypesAppConfig';
import MailTemplatesAppConfig from '@ameroservices-platform/attraction-backend/app/main/apps/settings/mailTemplates/MailTemplatesAppConfig';
import UserManagementAppConfig from '@ameroservices-platform/attraction-backend/app/main/apps/settings/userManagement/UserManagementAppConfig';

const appsConfigs = [
	TaxRatesAppConfig,
	ProductGroupsAppConfig,
	MailTemplatesAppConfig,
	IncrementalNumbersAppConfig,
	SubscriptionTypesAppConfig,
	LocationsAppConfig,
	ListsAppConfig,
	CountriesAppConfig,
	DiscountCodeTypesAppConfig,
	UserManagementAppConfig
];

export default appsConfigs;
