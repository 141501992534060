import React from 'react';
import authRoles from '../../../auth/authRoles';

const ImportJobImportJob = React.lazy(() => import('./importJob/ImportJob'));
const ImportJobsImportJobs = React.lazy(() => import('./importJobs/ImportJobs'));

const ImportJobsAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/import-jobs/:importJobUid',
			element: <ImportJobImportJob />
		},
		{
			path: '/apps/import-jobs',
			element: <ImportJobsImportJobs />
		}
	]
};

export default ImportJobsAppConfig;
