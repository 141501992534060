import authRoles from '../../../../auth/authRoles';
import { lazy } from 'react';

const IncrementalNumbersIncrementalNumbers = lazy(() => import('./incrementalNumbers/IncrementalNumbers'));

const IncrementalNumbersAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/settings/incremental-numbers',
			element: <IncrementalNumbersIncrementalNumbers />
		}
	]
};

export default IncrementalNumbersAppConfig;
