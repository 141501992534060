import React, { lazy } from 'react';
import authRoles from '../../../../auth/authRoles';

const Navigation = lazy(() => import('./navigations/Navigation'));
const Navigations = lazy(() => import('./navigations/Navigation'));

const NavigationsAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/frontend-settings/navigations/:navigation',
			element: <Navigation />
		},
		{
			path: '/apps/frontend-settings/navigations',
			element: <Navigations />
		}
	]
};

export default NavigationsAppConfig;
