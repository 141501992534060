import firebaseService from '@ameroservices-platform/shared/services/firebase';

const organisationsCollectionName = 'organisations';

export const syncTypes = Object.freeze({
	PRODUCT_SYNC: 'productSync',
	CUSTOMER_SYNC: 'customerSync'
});

const organisationsListener = callback => {
	const db = firebaseService.getRootDB();
	return db
		.collection(organisationsCollectionName)
		.where('deleted', '==', false)
		.onSnapshot(querySnapshot => {
			const organisations = [];
			querySnapshot.forEach(doc => {
				const docData = doc.data();
				organisations.push({ ...docData, id: doc.id });
			});
			callback(organisations);
		});
};

const organisationListener = (organisationId, callback) => {
	const db = firebaseService.getRootDB();
	return db
		.collection(organisationsCollectionName)
		.doc(organisationId)
		.onSnapshot(doc => {
			const data = { ...doc.data(), id: doc.id };
			callback(data);
		});
};

const organisationUsersListener = (organisationId, callback) => {
	const db = firebaseService.getRootDB();
	return db
		.collection('organisations')
		.doc(organisationId)
		.collection('organisation_users')
		.where('deleted', '==', false)
		.onSnapshot(querySnapshot => {
			const users = [];
			querySnapshot.forEach(doc => {
				const docData = doc.data();
				users.push({ ...docData, id: doc.id });
			});
			callback(users);
		});
};

export const flexposLinksListener = (organisationId, callback) => {
	const db = firebaseService.getRootDB();
	return db
		.collection('organisations')
		.doc(organisationId)
		.collection('flexposLinks')
		.where('deleted', '==', false)
		.onSnapshot(querySnapshot => {
			const flexposLinks = [];
			querySnapshot.forEach(doc => {
				const docData = doc.data();
				flexposLinks.push({ ...docData, id: doc.id });
			});
			callback(flexposLinks);
		});
};

export const customersListener = (organisationId, callback) => {
	const db = firebaseService.getRootDB();
	return db
		.collection('organisations')
		.doc(organisationId)
		.collection('customers')
		.where('deleted', '==', false)
		.where('isAnonymous', '==', false)
		.where('isAssociated', '==', false)
		.onSnapshot(querySnapshot => {
			const customers = [];
			querySnapshot.forEach(doc => {
				const docData = doc.data();
				customers.push({ ...docData, id: doc.id });
			});
			callback(customers);
		});
};

export const locationsListener = (organisationId, callback) => {
	const db = firebaseService.getRootDB();
	return db
		.collection('organisations')
		.doc(organisationId)
		.collection('locations')
		.where('deleted', '==', false)
		.onSnapshot(querySnapshot => {
			const locations = [];
			querySnapshot.forEach(doc => {
				const docData = doc.data();
				locations.push({ ...docData, id: doc.id });
			});
			callback(locations);
		});
};

const updateOrganisation = (organisationId, changesObject) => {
	const db = firebaseService.getRootDB();
	return db.collection(organisationsCollectionName).doc(organisationId).update(changesObject);
};

const createOrganisation = async formData => {
	return firebaseService.callFunctionByName('organisationCreateOrganisation', formData);
};

export const createOrganisationUser = async data => {
	const resp = await firebaseService.callFunctionByName('organisationOrganisationUserCreateUser', data);
	return resp.data;
};

export const updateOrganisationUser = async (organisationUid, uid, data) => {
	const resp = await firebaseService.callFunctionByName('organisationOrganisationUserUpdateUser', {
		userUid: uid,
		organisationUid,
		...data
	});
	return resp.data;
};

export const deleteOrganisationUser = async (organisationUid, uid) => {
	const resp = await firebaseService.callFunctionByName('organisationOrganisationUserDeleteUser', {
		userUid: uid,
		organisationUid
	});
	return resp.data;
};

export const sendResetPasswordRequest = async (organisationUid, uid) => {
	const resp = await firebaseService.callFunctionByName('organisationOrganisationUserResetUserPassword', {
		userUid: uid,
		organisationUid
	});
	return resp.data;
};

export const createFlexposLink = async data => {
	const resp = await firebaseService.callFunctionByName('organisationFlexposLinkCreateFlexposLink', data);
	return resp.data;
};

export const setFlexposLinkCustomerSync = async (organisationUid, uid) => {
	const resp = await firebaseService.callFunctionByName('organisationFlexposLinkSetFlexposLinkCustomerSync', {
		flexposLinkUid: uid,
		organisationUid
	});
	return resp.data;
};

export const updateFlexposLink = async (organisationUid, uid, data) => {
	const resp = await firebaseService.callFunctionByName('organisationFlexposLinkUpdateFlexposLink', {
		flexposLinkUid: uid,
		organisationUid,
		...data
	});
	return resp.data;
};

export const deleteFlexposLink = async (organisationUid, uid) => {
	const resp = await firebaseService.callFunctionByName('organisationFlexposLinkDeleteFlexposLink', {
		flexposLinkUid: uid,
		organisationUid
	});
	return resp.data;
};

export const regenerateTokenFlexposLink = async (organisationUid, uid) => {
	const resp = await firebaseService.callFunctionByName('organisationFlexposLinkRegenerateFlexposLinkToken', {
		flexposLinkUid: uid,
		organisationUid
	});
	return resp.data;
};

export const getFlexposLinkWithSyncActiveExists = async syncType => {
	const resp = await firebaseService.callFunctionByName('organisationFlexposLinkGetFlexposLinkWithSyncActiveExists', {
		syncType
	});
	return resp.data;
};

export const getFlexposLinkWithSyncActive = async syncType => {
	const organisationDbRef = firebaseService.getOrganisationRootDB();

	const flexposLinksWithProductSync = await organisationDbRef
		.collection('flexposLinks')
		.where('deleted', '==', false)
		.where(`${syncType}`, '==', true)
		.get();

	if (
		flexposLinksWithProductSync &&
		flexposLinksWithProductSync.docs &&
		flexposLinksWithProductSync.docs.length === 1
	) {
		return flexposLinksWithProductSync.docs[0].data();
	}
	return false;
};

export {
	organisationsListener,
	organisationListener,
	organisationUsersListener,
	updateOrganisation,
	createOrganisation
};
