import { authRoles } from '@ameroservices-platform/attraction-backend/app/auth';
import store from '@ameroservices-platform/attraction-backend/app/store';
import { logoutUser } from '@ameroservices-platform/attraction-backend/app/auth/store/userSlice';

const LogoutConfig = {
	routes: [
		{
			path: 'logout',
			element: () => {
				store.dispatch(logoutUser());
				return 'Logging out..';
			}
		}
	]
};

export default LogoutConfig;
