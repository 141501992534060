import { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const EventSchedylersEventGroupEventScheduler = lazy(() => import('./eventScheduler/EventScheduler'));
const EventSchedulersEventGroup = lazy(() => import('./eventSchedulers/EventSchedulers'));
const EventSchedulers = lazy(() => import('./eventSchedulers/EventSchedulers'));

const EventSchedulersAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/settings/event-schedulers/:eventGroupUid/:eventSchedulerUid',
			element: <EventSchedylersEventGroupEventScheduler />
		},
		{
			path: '/apps/settings/event-schedulers/:eventGroupUid',
			element: <EventSchedulersEventGroup />
		},
		{
			path: '/apps/settings/event-schedulers',
			element: <EventSchedulers />
		}
	]
};

export default EventSchedulersAppConfig;
