import { Chip, Icon } from '@mui/material';

function OrganisationSelectorChip({ icon, children }) {
	return (
		<Chip
			icon={<Icon>{icon}</Icon>}
			label={children}
			onClick={e => {
				e.preventDefault();
			}}
			variant="outlined"
		/>
	);
}

export default OrganisationSelectorChip;
