import { lazy } from 'react';
import authRoles from '../../../../auth/authRoles';

const SubscriptionTypeSubscriptionType = lazy(() => import('./subscriptionType/SubscriptionType'));
const SubscriptionTypesSubscriptionTypes = lazy(() => import('./subscriptionTypes/SubscriptionTypes'));

const SubscriptionTypesAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/settings/subscription-types/:subscriptionTypeUid',
			element: <SubscriptionTypeSubscriptionType />
		},
		{
			path: '/apps/settings/subscription-types',
			element: <SubscriptionTypesSubscriptionTypes />
		}
	]
};

export default SubscriptionTypesAppConfig;
