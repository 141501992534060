import React, { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const Organisations = lazy(() => import('./organisations/Organisations'));
const Organisation = lazy(() => import('./organisation/Organisation'));

const OrganisationsAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/organisations/:organisationUid',
			element: <Organisation />
		},
		{
			path: '/apps/organisations',
			element: <Organisations />
		}
	]
};

export default OrganisationsAppConfig;
