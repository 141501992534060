import React from 'react';
import authRoles from '../../../auth/authRoles';

const CustomersCustomers = React.lazy(() => import('./customers/Customers'));
const CustomerCustomer = React.lazy(() => import('./customer/Customer'));

const CustomersAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/customers/:customerUid',
			element: <CustomerCustomer />
		},
		{
			path: '/apps/customers',
			element: <CustomersCustomers />
		}
	]
};

export default CustomersAppConfig;
