import { lazy } from 'react';
import authRoles from '../../../../auth/authRoles';

const UserManagementPage = lazy(() => import('./UserManagementPage'));

const UserManagementAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/settings/user-management',
			element: <UserManagementPage />
		}
	]
};
export default UserManagementAppConfig;
