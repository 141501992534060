import React, { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const CommunicationTemplates = lazy(() => import('./CommunicationTemplates'));
const CommunicationTemplate = lazy(() => import('./CommunicationTemplate'));

const CommunicationTemplatesAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/communication-templates',
			element: <CommunicationTemplates />
		},
		{
			path: '/apps/communication-templates/:templateId',
			element: <CommunicationTemplate />
		}
	]
};

export default CommunicationTemplatesAppConfig;
