import authRoles from '../../../auth/authRoles';
import { lazy } from 'react';

const EventAttributeGroups = lazy(() => import('./eventAttributeGroup/EventAttributeGroup'));

const EventAttributeGroupsAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/event-attribute-groups/:eventAttributeGroup',
			element: <EventAttributeGroups />
		},
		{
			path: '/apps/event-attribute-groups',
			element: <EventAttributeGroups />
		}
	]
};

export default EventAttributeGroupsAppConfig;
