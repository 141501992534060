import { lazy } from 'react';
import authRoles from '../../../auth/authRoles';
const FlexposSettingsTicketSale = lazy(() => import('./ticketSale/flexposSettings/FlexposSettings'));
const FlexposSettingsMembers = lazy(() => import('./members/flexposSettings/FlexposSettings'));

const FlexposSettingsAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/flexpos-settings/ticket-sale',
			element: <FlexposSettingsTicketSale />
		},
		{
			path: '/apps/flexpos-settings/members',
			element: <FlexposSettingsMembers />
		}
	]
};

export default FlexposSettingsAppConfig;
