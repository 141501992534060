import React from 'react';
import authRoles from '../../../auth/authRoles';

const TicketTicket = React.lazy(() => import('./ticket/Ticket'));
const TicketsTickets = React.lazy(() => import('./tickets/Tickets'));

const TicketsAppConfig = {
	settings: {
		layout: {}
	},
	auth: ['tickets.view'],
	routes: [
		{
			path: '/apps/tickets/:customerUid/:ticketUid',
			element: <TicketTicket />
		},
		{
			path: '/apps/tickets',
			element: <TicketsTickets />
		}
	]
};

export default TicketsAppConfig;
