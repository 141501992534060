import authRoles from '../../../../auth/authRoles';
import { lazy } from 'react';

const Theme = lazy(() => import('./theme/Theme'));

const ThemeAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/frontend-settings/theme',
			element: <Theme />
		}
	]
};

export default ThemeAppConfig;
