import { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const Event = lazy(() => import('./event/Event'));
const Events = lazy(() => import('./events/Events'));
const EventsTable = lazy(() => import('./events/EventsTable'));

const EventsAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/event/:eventGroupUid/:eventUid',
			element: <Event />
		},
		{
			path: '/apps/manual-events/:eventGroupUid',
			element: <Events />
		},
		{
			path: '/apps/manual-events',
			element: <Events />
		},
		{
			path: '/apps/automatic-events/:eventGroupUid',
			element: <Events />
		},
		{
			path: '/apps/automatic-events',
			element: <Events />
		}, // CHANGE if feature flag eventGroupType is removed
		{
			path: '/apps/events-table/:eventGroupUid',
			element: <EventsTable />
		},
		{
			path: '/apps/events/:eventGroupUid',
			element: <Events />
		},
		{
			path: '/apps/events',
			element: <Events />
		}
	]
};

export default EventsAppConfig;
