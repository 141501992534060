const permissionsConfig = {
	'system.user.management': {
		category: 'Systemopsætning',
		label: 'Oprettelse og ændring af brugere',
		description: 'Systemopsætning af dit system'
	},
	'system.role.management': {
		category: 'Systemopsætning',
		label: 'Oprettelse og ændring af roller'
	},
	'system.incrementalNumbers': {
		category: 'Systemopsætning',
		label: 'Opret, ret og slet fortløbende numre'
	},
	'system.communicationTemplates': {
		category: 'Systemopsætning',
		label: 'Ret kommunikationsskabeloner'
	},
	'system.locations': {
		category: 'Systemopsætning',
		label: 'Opret, ret og slet lokationer'
	},
	'system.lists': {
		category: 'Systemopsætning',
		label: 'Opret, ret og slet lister'
	},
	'system.countries': {
		category: 'Systemopsætning',
		label: 'Tilføj eller fjern lande'
	},
	'system.financeDepartments': {
		category: 'Systemopsætning',
		label: 'Opret, ret og slet finansafdelinger'
	},
	'dashboard.view': {
		category: 'Dashboard',
		label: 'Visning af side',
		description: 'Dashboard'
	},
	'tickets.view': {
		category: 'Billetter',
		label: 'Visning af side',
		description: 'Billetter oprettet i systemet'
	},
	'subscriptions.view': {
		category: 'Medlemskaber',
		label: 'Visning af side',
		description: 'Medlemskaber oprettet i systemet'
	},
	'customers.view': {
		category: 'Kunder',
		label: 'Visning af side',
		description: 'Kunder oprettet i systemet'
	},
	'customers.resendMail': {
		category: 'Kunder',
		label: 'Gensend mails for kunder'
	},
	'customers.anonymize': {
		category: 'Kunder',
		label: 'Anonymiser kunder'
	},
	'customers.impersonate': {
		category: 'Kunder',
		label: 'Impersonate kunder'
	},
	'orders.view': {
		category: 'Ordrer',
		label: 'Visning af side',
		description: 'Ordrer oprettet i systemet'
	},
	'refunds.view': {
		category: 'Refunderinger',
		label: 'Visning af side',
		description: 'Refunderinger oprettet i systemet'
	},
	'events.view': {
		category: 'Begivenheder',
		label: 'Visning af side',
		description: 'Begivenheder oprettet i systemet'
	},
	'events.edit': {
		category: 'Begivenheder',
		label: 'Ret begivenhed'
	},
	'events.cancel': {
		category: 'Begivenheder',
		label: 'Aflys begivenhed'
	},
	'statistics.reports': {
		category: 'Statistik og rapporter',
		description: 'Træk rapporter, se statistikker og opsætning af rapporter',
		label: 'Se og opret rapporter'
	},
	'statistics.recurringScheduledJobs': {
		category: 'Statistik og rapporter',
		label: 'Opret gentagende rapporter'
	},
	'statistics.statistics': {
		category: 'Statistik og rapporter',
		label: 'Statistikmodul'
	},
	'statistics.budgets': {
		category: 'Statistik og rapporter',
		label: 'Budgetter til statistikmodulet'
	},
	'statistics.import': {
		category: 'Statistik og rapporter',
		label: 'Importering af data'
	},
	'setup.products': {
		category: 'Opsætning',
		description: 'Opsætning af produkter, priser, begivenheder m.m.',
		label: 'Se, opret, ret og slet produkter'
	},
	'setup.taxGroups': {
		category: 'Opsætning',
		label: 'Se og opret momskoder'
	},
	'setup.productGroups': {
		category: 'Opsætning',
		label: 'Se, opret, ret og slet produktgrupper'
	},
	'setup.followProducts': {
		category: 'Opsætning',
		label: 'Se, opret, ret og slet følgeprodukter'
	},
	'setup.discountCodeTypes.view': {
		category: 'Opsætning',
		label: 'Se rabatkodetyper'
	},
	'setup.discountCodeTypes.edit': {
		category: 'Opsætning',
		label: 'Opret, ret og slet rabatkodetyper'
	},
	'setup.subscriptionTypes': {
		category: 'Opsætning',
		label: 'Opret, ret og slet medlemskabstyper'
	},
	'setup.eventGroups': {
		category: 'Opsætning',
		label: 'Opret, ret og slet begivenhedsgruppere'
	},
	'setup.eventGroupSchedulers': {
		category: 'Opsætning',
		label: 'Opret, ret og slet begivenhedsplanlæggere'
	},
	'setup.eventAttributeGroups': {
		category: 'Opsætning',
		label: 'Opret, ret og slet attribut grupper'
	},
	'setup.frontend.pages': {
		category: 'Opsætning',
		label: 'Opret sider'
	},
	'setup.frontend.pageContent': {
		category: 'Opsætning',
		label: 'Ret sideindhold'
	},
	'setup.frontend.theme': {
		category: 'Opsætning',
		label: 'Ret tema'
	},
	'setup.frontend.faq': {
		category: 'Opsætning',
		label: 'Opret, ret og slet FAQ'
	},
	'setup.frontend.navigations': {
		category: 'Opsætning',
		label: 'Opret, ret og slet navigationer'
	},
	'setup.frontend.header': {
		category: 'Opsætning',
		label: 'Ret indhold på header'
	},
	'setup.frontend.footer': {
		category: 'Opsætning',
		label: 'Ret indhold på header'
	},
	'setup.flexpos.ticketSale': {
		category: 'Opsætning',
		label: 'Ret indstillinger på POS billetsalg'
	},
	'setup.flexpos.members': {
		category: 'Opsætning',
		label: 'Ret indstillinger på POS medlemsmodul'
	},
	'images.delete': {
		category: 'Filer',
		label: 'Slet filer'
	},
	'images.upload': {
		category: 'Filer',
		label: 'Upload filer'
	},
	'cms.advanced': {
		category: 'CMS',
		description: 'Indhold på sider og begivenhedsgrupper',
		label: 'Rette opsætninger og rette layout'
	}
};

export default permissionsConfig;
