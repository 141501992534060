import React from 'react';
import authRoles from '../../../auth/authRoles';

const NewSubscriptionNewSubscription = React.lazy(() => import('./newSubscription/NewSubscription'));
const SubscriptionSubscription = React.lazy(() => import('./subscription/Subscription'));
const SubscriptionsSubscriptions = React.lazy(() => import('./subscriptions/Subscriptions'));

const SubscriptionsAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/subscriptions/new/:customerUid/:subscriptionUid',
			element: <NewSubscriptionNewSubscription />
		},
		{
			path: '/apps/subscriptions/new',
			element: <NewSubscriptionNewSubscription />
		},
		{
			path: '/apps/subscriptions/details/:customerUid/:subscriptionUid',
			element: <SubscriptionSubscription />
		},
		{
			path: '/apps/subscriptions',
			element: <SubscriptionsSubscriptions />
		}
	]
};

export default SubscriptionsAppConfig;
