import authRoles from '../../../auth/authRoles';
import { lazy } from 'react';

const ExportJob = lazy(() => import('./exportJob/ExportJob'));
const ExportJobs = lazy(() => import('./exportJobs/ExportJobs'));

const ExportJobsAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/reports/:exportJobUid',
			element: <ExportJob />
		},
		{
			path: '/apps/reports',
			element: <ExportJobs />
		}
	]
};

export default ExportJobsAppConfig;
